import * as Yup from "yup";

export const jobOpeningValidation = Yup.object().shape({
  title: Yup.string()
    .required("Please enter job title")
    .max(50, "Job Name cannot be greater than 50 words"),
  description: Yup.string().required("Please enter description "),
  requirements: Yup.string().required("Please enter experience "),
  budget: Yup.string().required("Please enter budget "),
  vacancyCount: Yup.string()
    .required("Please enter vacancy no")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(1, "Must be exactly 1 digits"),
  jobType: Yup.string().required("Please enter job type "),
  status: Yup.string().required("Please select status "),
  location: Yup.array()
    .of(
      Yup.string())
    .required('Location is Required')
    .min(1, 'Min one location is required')
});
