import { AiFillFileImage } from "react-icons/ai";
import { GoPlusCircle } from "react-icons/go";
import React from "react";
import { formatDate } from "utils/date";
let alignValues = {
  left: "left",
  right: "right",
  center: "center",
};

const handleDownloadFile = (file) => {
  window.open(file, "_blank");
};

export const jobOpeningColum = [
  {
    title: "Job Name",
    field: "title",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className:
      "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#031b59] capitalize ",
    style: "",
    sorting: true,
  },
  {
    title: "New Applicants",
    field: "applicants",
    align: alignValues.left,
    className:
      "min-w-[10rem] w-fit px-[15px] h-[50px] text-[#031b59] capitalize",
    style: "",
  },
  {
    title: "Job Type",
    field: "jobType",
    align: alignValues.left,
    className:
      "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#031b59] capitalize",
    style: "",
  },
  {
    title: "Preferred Location",
    field: "preferredLocation",
    align: alignValues.left,
    className: "min-w-[16rem] px-[15px] h-[50px] text-[#031b59] capitalize",
    style: "",
  },
  {
    title: "Created By",
    field: "createdBy",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#031b59] capitalize",
    render: (value) => {
      return <>{value || "- - - - - "}</>;
    },
    style: "",
  },
  {
    title: "Created Date",
    field: "createdAt",
    align: alignValues.left,
    render: (value) => formatDate(value,"DD MMM YYYY"),
    className: "min-w-[151px] px-[15px] h-[50px] text-[#031b59] capitalize",
    style: "",
  },
  {
    title: "Budget",
    field: "budget",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#031b59] capitalize",
    style: "",
  },
];

export const jobApplicantColum = [
  {
    title: "Name",
    field: "title",
    inputcheck: "checkbox",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className:
      "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
    style: "",
    sorting: true,
  },
  {
    title: "Applied For",
    field: "appliedFor",
    align: alignValues.left,
    className: "capitalize",
    style: "",
  },
  {
    title: "Applied Date",
    field: "appliedDate",
    align: alignValues.left,
    render: (value) => formatDate(value,"DD MMM YYYY"),
    className:
      "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
    style: "",
  },
  {
    title: "Resume",
    field: "resume",
    align: alignValues.left,
    render: (value, Job) => {
      return (
        <div
          className="flex gap-1 items-center cursor-pointer"
          onClick={() => handleDownloadFile(Job.resumeUrl)}
        >
          <AiFillFileImage fontSize="30px" color="skyBlue" />
        </div>
      );
    },

    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
    style: "",
  },
  {
    title: "Handled By",
    field: "handledBy",
    align: alignValues.left,
    render: (value) => {
      return (
        <div
          className="text-[0.875rem] text-[#031B59] flex items-center cursor-pointer
                              justify-start w-[12rem] py-4 gap-[0.25rem] px-[0.5rem]"
        >
          <GoPlusCircle />
          <span>Add Resources</span>
          {value}
        </div>
      );
    },
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
    style: "",
  },
  {
    title: "Status",
    field: "status",
    align: alignValues.left,
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868] capitalize",
    style: "",
  },
];

export const currentOpeningColumn = [
  {
    title: "Name",
    field: "jobFullName",
    inputcheck: "checkbox",
    align: alignValues.left,
    render: (fieldValue) => fieldValue,
    className: "min-w-[10rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
    sorting: true,
  },
  {
    title: "Applied For",
    field: "jobName",
    align: alignValues.left,
    className: "",
    style: "",
  },
  {
    title: "Applied Date",
    field: "appliedDate",
    align: alignValues.left,
    render: (value) => formatDate(value,"DD MMM YYYY"),
    className: "min-w-[7rem] w-fit px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Resume",
    field: "resume",
    align: alignValues.left,
    render: (value, Job) => {
      return (
        <div
          className="flex gap-1 items-center cursor-pointer"
          onClick={() => handleDownloadFile(Job.resumeUrl)}
        >
          <AiFillFileImage fontSize="30px" color="skyBlue" />
        </div>
      );
    },
    className: " px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Handled By",
    field: "handledBy",
    align: alignValues.left,
    render: (value) => {
      return (
        <div
          className="text-[0.875rem] text-[#031B59] flex items-center cursor-pointer
                              justify-start w-[12rem] py-4 gap-[0.25rem] px-[0.5rem]"
        >
          <GoPlusCircle />
          <span>Add Resources</span>
          {value}
        </div>
      );
    },
    className: "min-w-[151px] px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
  {
    title: "Status",
    field: "status",
    align: alignValues.left,
    className: " px-[15px] h-[50px] text-[#686868]",
    style: "",
  },
];

export const jopbApplicationsColumn = [
  {
    title: "Job Name",
    field: "job_name",
    align: alignValues.left,
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#686868]",
  },
  {
    title: "Full Name",
    field: "fullName",
    align: alignValues.left,
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#686868]",
  },
  {
    title: "Applied Date",
    field: "appliedDate",
    align: alignValues.left,
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#686868]",
  },
  {
    title: "Contact No",
    field: "contactNo",
    align: alignValues.left,
    className: "min-w-[16rem] w-fit px-[15px] h-[50px] text-[#686868]",
  },
];
